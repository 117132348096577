<template>
	<div>
		<v-row>
			<v-col cols="12" md="4" sm="12">
				<!-- 프로필 -->
				<my-page-dashboard-profile />
			</v-col>
			<v-col cols="12" md="4" sm="12" :class="isMdAndUp ? 'pl-0' : 'pt-0'">
				<!-- 팀보드 -->
				<my-page-dashboard-member :key="componentKey" />
			</v-col>
			<v-col cols="12" md="4" sm="12" :class="isMdAndUp ? 'pl-0' : 'pt-0'">
				<my-page-dashboard-main :key="componentKey" />
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-1">
				<!-- 마이데이터 -->
				<my-page-main-my-data />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { onMounted, computed, ref } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import MyPageMainMyData from './MyPageMainMyData.vue'
import MyPageDashboardMain from './components/MyPageDashboardMain.vue'
import MyPageDashboardMember from '../components/MyPageDashboardMember.vue'
import MyPageDashboardProfile from '../components/MyPageDashboardProfile.vue'

export default {
	components: {
		MyPageDashboardProfile,
		MyPageDashboardMember,
		MyPageMainMyData,
		MyPageDashboardMain,
	},
	setup() {
		const $vuetify = getVuetify()
		const componentKey = ref(0)
		const forceRerender = () => {
			componentKey.value += 1
		}

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		onMounted(() => {
			forceRerender()
		})

		return {
			isMdAndUp,
			componentKey,
		}
	},
}
</script>
<style lang="scss" scoped>
.notice-box {
	width: 60%;
	cursor: pointer;
	border-bottom: 1px solid #cccccc;
	padding: 15px 15px 15px 10px;

	&:hover {
		background-color: #e6e6e6;
		transition: 0.5s;
	}
}
</style>
