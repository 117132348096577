<template>
	<v-card max-height="320" min-height="320">
		<v-card-title>
			<div>
				<v-icon color="primary">mdi-chart-bar</v-icon>
				<span class="primary--text pl-2">팀보드</span>
			</div>
			<v-spacer />
			<v-select
				:items="items"
				v-model="selected"
				item-text="name"
				item-value="value"
				hide-details
				dense
				outlined
			/>
		</v-card-title>
		<v-card-subtitle class="pb-0">
			<span class="secondary--text text-xxs d-block">
				*일정 데이터가 쌓이면 개인 데이터로 반영됩니다
			</span>
		</v-card-subtitle>
		<div
			id="member-bar-chart"
			style="overflow: hidden"
			ref="memberBarChart"
		></div>
	</v-card>
</template>

<script>
import { ref, reactive, onMounted } from '@vue/composition-api'

export default {
	components: {},
	setup() {
		const memberBarChart = ref(null)
		const selected = ref('30일')
		const items = ref(['30일', '60일', '90일'])
		const options = reactive({
			chart: {
				height: '240',
				width: '95%',
				type: 'line',
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			colors: ['#00E396'],
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: 'datetime',
				categories: [
					'4/11/2022',
					'4/12/2022',
					'4/13/2022',
					'4/14/2022',
					'4/15/2022',
					'4/16/2022',
					'4/17/2022',
					'4/18/2022',
					'4/19/2022',
				],
				tickAmount: 10,
				labels: {
					formatter: function (value, timestamp, opts) {
						return opts.dateFormatter(new Date(timestamp), 'MM-dd')
					},
				},
			},
			stroke: {
				curve: 'straight',
			},
			series: [
				{
					name: '합계',
					data: [22, 40, 30, 40, 50, 60, 31, 12, 23],
				},
			],
		})

		onMounted(() => {
			const barChartElement = document.querySelector('#member-bar-chart')
			// eslint-disable-next-line no-undef
			const barChart = new ApexCharts(barChartElement, options)
			barChart.render()
		})

		return {
			selected,
			items,
			memberBarChart,
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.v-select {
		max-width: 81px;
	}

	.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
		> .v-input__control
		> .v-input__slot {
		padding: 0 10px;
	}

	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 36px;
	}
}
</style>
