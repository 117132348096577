<template>
	<v-dialog
		v-model="isOpenBusinessCardDialog"
		persistent
		max-width="452"
		:fullscreen="!isSmAndUp"
	>
		<v-card>
			<v-card-title>
				내 명함
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-business-card-dialog', false)"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider />
			<div class="wrapper pa-6" :class="{ 'px-3': !isMdAndUp }">
				<div class="header">
					<span class="text-lg">
						{{ phoneHyphenFormat(profile.phoneNumber) | krPhoneFormat }}
					</span>
				</div>

				<div class="business-card">
					<div class="left">
						<div class="name">{{ profile.name }}</div>
						<div class="company-name">IT Marketer</div>
						<div class="phone-number">
							M. {{ phoneHyphenFormat(profile.phoneNumber) | krPhoneFormat }}
						</div>
						<div class="hompage">www.itmarketer.org</div>
					</div>
					<div class="right">
						<div class="logo">
							<img
								class="logo"
								:src="require('@/assets/logo/logo.png')"
								:style="{ width: isMdAndUp ? '28%' : '40%' }"
								style="position: relative; right: 2px"
							/>
						</div>
						<div class="qr-code">
							<qrcode-vue
								:value="qrText"
								:size="isMdAndUp ? 88 : 90"
								level="L"
								v-if="profile"
							/>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="name">{{ profile.name }}</div>
					<div class="company-name">IT Marketer</div>
					<div class="corp-name">포도나무랩 주식회사</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { phoneHyphenFormat, getVuetify } from '@core/utils'
import { krPhoneFormat, removeSubStringFromString } from '@/filter'

import QrcodeVue from 'qrcode.vue'

export default {
	components: {
		QrcodeVue,
	},
	props: {
		isOpenBusinessCardDialog: {
			type: Boolean,
			required: true,
		},
		profile: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const $vuetify = getVuetify()
		const qrText = ref(
			'BEGIN:VCARD\n' +
				'VERSION:3.0\n' +
				'N:' +
				props.profile.name +
				';\n' +
				'FN:' +
				props.profile.name +
				'\n' +
				'NICKNAME:' +
				props.profile.uid +
				'\n' +
				'ORG:포도나무랩 주식회사\n' +
				'TITLE:IT Marketer\n' +
				'ADR:;;;;;;\n' +
				'TEL;WORK;VOICE:\n' +
				'TEL;CELL:' +
				krPhoneFormat(props.profile.phoneNumber || '') +
				'\n' +
				'TEL;FAX:\n' +
				'EMAIL;WORK;INTERNET:' +
				props.profile.email +
				'\n' +
				'URL:www.itmarketer.org\n' +
				'END:VCARD',
		)

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		return {
			qrText,
			isSmAndUp,
			isMdAndUp,

			phoneHyphenFormat,
			removeSubStringFromString,
		}
	},
}
</script>
<style lang="scss" scoped>
.wrapper {
	background-color: #f2f2f2;

	.header {
		margin-bottom: 15px;
	}

	.business-card {
		border: 1px solid #a6a6a6;
		background-color: white;
		padding-top: 18px;
		padding-left: 14px;
		padding-right: 12px;
		padding-bottom: 14px;
		display: flex;

		.left {
			line-height: 1.2;

			.name {
				font-size: 18px;
				font-weight: 800;
			}

			.company-name {
				color: #269ffb;
				font-weight: 600;
			}
		}

		.right {
			text-align: right;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			img {
				&.logo {
					position: relative;
					top: -9px;
					right: -10px;
				}
			}

			.qr-code {
				position: relative;
				top: 12px;
			}
		}
	}

	.footer {
		margin-top: 20px;
		line-height: 1.2;

		.name {
			font-weight: 900;
		}
		.company-name {
			font-weight: 600;
		}
	}
}
</style>
