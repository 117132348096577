<template>
	<v-card max-height="320" min-height="320">
		<v-card-title>
			<v-icon color="primary">mdi-chart-bar</v-icon>
			<div class="primary--text pl-2">마이데이터</div>
		</v-card-title>
		<v-card-subtitle class="pb-0">
			<div class="secondary--text text-xs">
				*일정 데이터가 쌓이면 개인 데이터로 반영됩니다
			</div>
		</v-card-subtitle>
		<div id="my-bar-chart" style="overflow: hidden"></div>
	</v-card>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api'

export default {
	components: {},
	setup() {
		const options = reactive({
			chart: {
				height: '240',
				width: '95%',
				type: 'bar',
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					columnWidth: '30%',
					borderRadius: 5,
					dataLabels: {
						enabled: false,
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function () {
					return ''
				},
				style: {
					fontSize: '15px',
					colors: ['#ffffff'],
				},
			},
			series: [
				{
					name: '',
					data: [
						{
							x: '자격검정',
							y: 54,
						},
						{
							x: '이벤트',
							y: 30,
						},
						{
							x: '기부',
							y: 30,
						},
						{
							x: '봉사',
							y: 30,
						},
						{
							x: '취소',
							y: 52,
						},
						{
							x: '노쇼',
							y: 12,
						},
					],
				},
			],
			xaxis: {
				// categories: ['자격검정', '이벤트', '기부', '봉사', '취소', '노쇼'],
				categories: [' ', ' ', ' ', ' ', ' ', ' '],
				axisBorder: {
					show: true,
				},
				axisTicks: {
					show: true,
				},
				crosshairs: {
					fill: {
						type: 'gradient',
						gradient: {
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
				tooltip: {
					enabled: true,
				},
			},
		})

		onMounted(() => {
			const barChartElement = document.querySelector('#my-bar-chart')
			// eslint-disable-next-line no-undef
			const barChart = new ApexCharts(barChartElement, options)
			barChart.render()
		})
	},
}
</script>
