<template>
	<v-dialog
		v-model="isOpenLicenseIssueDialog"
		persistent
		max-width="650"
		:fullscreen="!isSmAndUp"
	>
		<v-card>
			<v-card-title>
				자격증 발급하기
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-license-issue-dialog', false)"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card-actions class="d-flex flex-column">
				<div
					class="d-flex align-center"
					:class="{ 'flex-column': isSmAndDown }"
				>
					<div
						class="text-center"
						:class="{ 'mb-4': isSmAndDown }"
						:style="isSmAndDown ? 'width: 100%' : 'width: 50%'"
					>
						<canvas
							id="canvas-license"
							:style="{ 'position: relative; right: 20px;': !isSmAndDown }"
						></canvas>
						<v-btn
							color="accent"
							class="text-sm d-block mx-auto"
							@click="issueLicenseDownloadUrl"
							large
							max-width="100px"
							:disabled="!licenseDownloadUrl"
							v-if="isSmAndDown"
						>
							증서형 발급하기
						</v-btn>
					</div>
					<div
						class="text-center"
						:style="
							isSmAndDown ? 'width: 100%;' : 'width: 50%; margin-left: 5px'
						"
					>
						<canvas id="canvas-card-license"></canvas>
						<v-btn
							color="accent"
							class="text-sm d-block mx-auto"
							@click="issueLicenseCardDownloadUrl"
							max-width="100px"
							:disabled="!licenseCardDownloadUrl"
							large
							v-if="isSmAndDown"
						>
							카드형 발급하기
						</v-btn>
					</div>
				</div>
				<div class="d-flex justify-space-between" style="width: 100%">
					<div style="width: 50%" class="text-center">
						<v-btn
							color="accent"
							class="text-sm"
							@click="issueLicenseDownloadUrl"
							max-width="100px"
							:disabled="!licenseDownloadUrl"
							large
							v-if="isSmAndUp"
						>
							증서형 발급하기
						</v-btn>
					</div>
					<div style="width: 50%" class="text-center">
						<v-btn
							color="accent"
							class="text-sm mx-auto"
							@click="issueLicenseCardDownloadUrl"
							max-width="100px"
							:disabled="!licenseCardDownloadUrl"
							large
							v-if="isSmAndUp"
						>
							카드형 발급하기
						</v-btn>
					</div>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { watch, computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

export default {
	components: {},
	props: {
		isOpenLicenseIssueDialog: {
			type: Boolean,
			required: true,
		},
		licenseDownloadUrl: {
			type: String,
		},
		licenseCardDownloadUrl: {
			type: String,
		},
	},
	setup(props) {
		const $vuetify = getVuetify()

		const renderPdfFile = async (url, elementId, _scale) => {
			const pdfjsLib = window['pdfjs-dist/build/pdf']

			// The workerSrc property shall be specified.
			pdfjsLib.GlobalWorkerOptions.workerSrc =
				'//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js'

			// Asynchronous download of PDF
			const loadingTask = await pdfjsLib.getDocument(url)

			loadingTask.promise.then(function (pdf) {
				// Fetch the first page
				const pageNumber = 1
				pdf.getPage(pageNumber).then(function (page) {
					const scale = _scale
					const viewport = page.getViewport({ scale: scale })

					// Prepare canvas using PDF page dimensions
					const canvas = document.getElementById(elementId)

					const context = canvas.getContext('2d')
					canvas.height = viewport.height
					canvas.width = viewport.width

					// Render PDF page into canvas context
					const renderContext = {
						canvasContext: context,
						viewport: viewport,
					}
					const renderTask = page.render(renderContext)
					renderTask.promise.then(function () {})
				})
			})
		}

		const issueLicenseDownloadUrl = () => {
			window.open(props.licenseDownloadUrl)
		}
		const issueLicenseCardDownloadUrl = () => {
			window.open(props.licenseCardDownloadUrl)
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		watch(
			() => props.isOpenLicenseIssueDialog,
			currentValue => {
				if (currentValue) {
					renderPdfFile(props.licenseDownloadUrl, 'canvas-license', 0.56)
					renderPdfFile(
						props.licenseCardDownloadUrl,
						'canvas-card-license',
						1.1,
					)
				}
			},
		)
		return {
			isSmAndUp,
			isSmAndDown,
			issueLicenseDownloadUrl,
			issueLicenseCardDownloadUrl,
		}
	},
}
</script>
