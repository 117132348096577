<template>
	<div>
		<div class="d-flex justify-end my-2 mr-2" v-if="licenseList.length > 0">
			<v-btn
				color="accent"
				@click="isOpenLicenseIdUploadDialog = !isOpenLicenseIdUploadDialog"
			>
				증명사진 올리기
			</v-btn>
		</div>
		<v-data-table
			height="200"
			:headers="tableColumnHeaders"
			:items="licenseList"
			no-data-text="내역이 존재하지 않습니다"
			hide-default-footer
		>
			<template v-slot:[`item.title`]="{ item }">
				{{ `${item.license.title} ${item.classNumber}기` }}
			</template>
			<template v-slot:[`item.issuedAt`]="{ item }">
				{{ item.issuedAt | _dateFormat }}
			</template>
			<template v-slot:[`item.idPhotoStatus`]>
				<v-chip
					small
					rounded
					:color="resolveIdPhotoStatusVariant(profile.idPhotoStatus)"
				>
					{{ resolveIdPhotoStatus(profile.idPhotoStatus) }}
				</v-chip>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn
					color="primary"
					:disabled="
						!profile.idPhotoUrl ||
						profile.idPhotoStatus !== idPhotoStatus.CONFIRMED.value ||
						!(item.licenseDownloadUrl || item.licenseCardDownloadUrl)
					"
					@click="isOpenLicenseIssueDialog = !isOpenLicenseIssueDialog"
				>
					자격증 발급하기
				</v-btn>
			</template>
		</v-data-table>
		<div v-if="licenseList.length > 0">
			<my-page-main-my-data-license-id-upload-dialog
				:id-photo-url="profile.idPhotoUrl"
				:is-open-license-id-upload-dialog.sync="isOpenLicenseIdUploadDialog"
			/>
			<my-page-main-my-data-license-issue-dialog
				:license-download-url="profile.licenseIssues[0].licenseDownloadUrl"
				:license-card-download-url="
					profile.licenseIssues[0].licenseCardDownloadUrl
				"
				:is-open-license-issue-dialog.sync="isOpenLicenseIssueDialog"
				v-if="profile.licenseIssues"
			/>
		</div>
	</div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'

import {
	resolveIdPhotoStatus,
	resolveIdPhotoStatusVariant,
} from '@core/utils/filter'
import { idPhotoStatus } from '@/helpers'
import store from '@/store'

import MarketLicenseService from '@/services/MarketLicenseService'

import MyPageMainMyDataLicenseIssueDialog from './MyPageMainMyDataLicenseIssueDialog.vue'
import MyPageMainMyDataLicenseIdUploadDialog from './MyPageMainMyDataLicenseIdUploadDialog.vue'

export default {
	components: {
		MyPageMainMyDataLicenseIssueDialog,
		MyPageMainMyDataLicenseIdUploadDialog,
	},
	props: {},
	setup() {
		const tableColumnHeaders = reactive([
			{
				text: '자격증',
				value: 'title',
				sortable: false,
			},
			{
				text: '발급번호',
				value: 'issueCode',
				sortable: false,
			},
			{
				text: '발급일',
				value: 'issuedAt',
				sortable: false,
			},
			{
				text: '발급상태',
				value: 'idPhotoStatus',
				sortable: false,
			},
			{
				text: '발급',
				value: 'actions',
				sortable: false,
			},
		])
		const licenseList = ref([])
		const isOpenLicenseIssueDialog = ref(false)
		const isOpenLicenseIdUploadDialog = ref(false)
		const profile = computed(() => store.getters['user/getMeDetail'])

		const getMeLicenses = async () => {
			try {
				const data = await MarketLicenseService.getMeLicenses()
				licenseList.value = data
			} catch (e) {
				console.error(e)
			}
		}
		getMeLicenses()

		return {
			tableColumnHeaders,
			licenseList,
			profile,
			isOpenLicenseIssueDialog,
			isOpenLicenseIdUploadDialog,
			idPhotoStatus,

			resolveIdPhotoStatus,
			resolveIdPhotoStatusVariant,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}

::v-deep {
	.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
		> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
		.v-slide-group__prev {
		display: none;
	}
}
</style>
