<template>
	<v-card max-height="320" height="320">
		<v-card-title
			class="d-flex flex-column justify-center align-center"
			style="height: 100%"
		>
			<div class="text-center">
				<v-avatar size="120" class="cursor-pointer">
					<v-img
						@click="$refs.refInputEl.click()"
						:src="
							profile.publicPictureUrl ||
							require('@/assets/images/avatars/default.svg')
						"
					/>
				</v-avatar>
				<input
					ref="refInputEl"
					@change="uploadProfileFile($event)"
					type="file"
					accept="*"
					:hidden="true"
				/>
				<p class="mt-4 mb-0 text-base text-left">
					<strong>실명:</strong>
					{{ profile.name || '-' }}
				</p>
				<p class="mb-0 text-base text-left">
					<strong>유저명:</strong>
					{{ profile.uid }}
				</p>
				<p class="text-left mb-0">
					<template v-if="meLicenses">
						<div v-if="!meLicenses.waiting">
							<div class="text-base">
								<strong>자격:</strong>
								{{ meLicenses.license.title }} {{ meLicenses.classNumber }}기
							</div>
							<div class="secondary--text text-xs">
								<strong>발급번호</strong>
								{{ meLicenses.issueCode }}
							</div>
						</div>
						<div class="secondary--text text-base" v-else>
							<div>IT Marketer - 급</div>
						</div>
					</template>
					<template v-else>
						<div class="text-base">자격: {{ getRole() }}</div>
						<p class="mb-0 text-sm">IT마케터에 도전하세요</p>
					</template>
				</p>
				<p class="mb-2 text-base text-left">
					<strong>콘텐츠 마켓 레벨:</strong>
					{{ profile.forcedItemUploadLevel || profile.itemUploadLevel }}
				</p>
				<div class="d-flex justify-center">
					<template v-if="meLicenses">
						<v-btn
							small
							color="primary"
							@click="isOpenBusinessCardDialog = !isOpenBusinessCardDialog"
						>
							내 명함보기
						</v-btn>
					</template>
					<v-btn
						small
						@click="copyClipboard"
						color="info"
						class="ml-1 clipboard"
					>
						<v-icon small>mdi-attachment</v-icon>
						내 추천인 링크
					</v-btn>
				</div>
			</div>
		</v-card-title>
		<my-page-business-card-dialog
			:profile="profile"
			:is-open-business-card-dialog.sync="isOpenBusinessCardDialog"
		/>
		<v-snackbar v-model="snackbar" :timeout="timeout" style="z-index: 9999">
			내 추천인 링크가 복사되었습니다

			<template v-slot:action="{ attrs }">
				<v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
					닫기
				</v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import store from '@/store'
import { marketerRole } from '@/helpers'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'
import UserService from '@/services/UserService'
import AuthService from '@/services/UserService'

import MyPageBusinessCardDialog from './MyPageBusinessCardDialog.vue'

const FILE_SIZE_BYTE_LIMIT = 20000000

export default {
	components: {
		MyPageBusinessCardDialog,
	},
	setup() {
		const file = ref(null)
		const snackbar = ref(false)
		const timeout = ref(3500)
		const meLicenses = ref()
		const isOpenBusinessCardDialog = ref(false)
		const profile = computed(() => store.getters['user/getMeDetail'])

		const getMeLicenses = async () => {
			const [data] = await MarketLicenseService.getMeLicenses()
			meLicenses.value = data
		}
		getMeLicenses()

		const copyClipboard = () => {
			const referralLink = `${process.env.VUE_APP_DOMAIN_URL}/signup?referral_code=${profile.value.referralCode}`
			navigator.clipboard.writeText(referralLink).then(
				() => {
					snackbar.value = true
				},
				err => {
					console.error('Async: Could not copy text: ', err)
				},
			)
		}

		const getRole = () => {
			return (
				marketerRole[profile.value.marketerRole]?.name ?? marketerRole.None.name
			)
		}

		const uploadProfileFile = async $event => {
			const form = new FormData()
			file.value = $event.target.files[0]
			form.append('data', file.value)

			if (file.value.size > FILE_SIZE_BYTE_LIMIT) {
				warningSwal('파일은 20MB이하로 업로드 가능합니다')
				return
			}

			try {
				await UserService.uploadProfileFile(form)
				await AuthService.init()

				successSwal({
					html: '프로필을 업로드했습니다',
					allowOutsideClick: false,
				})
			} catch (e) {
				warningSwal('프로필을 업로드하는데 문제가 발생했습니다')
			}
		}

		return {
			profile,
			meLicenses,
			isOpenBusinessCardDialog,
			snackbar,
			timeout,

			getRole,
			copyClipboard,
			uploadProfileFile,
		}
	},
}
</script>
<style lang="scss">
.clipboard {
	.v-icon {
		transform: rotate(-45deg);
	}
}
</style>
