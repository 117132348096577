<template>
	<v-card min-height="320">
		<v-tabs v-model="tab" align-with-title class="elevation-0" elevation="0">
			<v-tab v-for="(item, index) in items" :key="'tab-' + index">
				{{ item }}
			</v-tab>
		</v-tabs>
		<div>
			<v-tabs-items v-model="tab" touchless>
				<v-tab-item>
					<v-data-table
						height="200"
						:headers="tableColumnHeaders"
						:items="projectList"
						no-data-text="내역이 존재하지 않습니다"
						hide-default-footer
					/>
				</v-tab-item>
				<v-tab-item>
					<v-data-table
						height="200"
						:headers="tableColumnHeaders"
						:items="projectList"
						no-data-text="내역이 존재하지 않습니다"
						hide-default-footer
					/>
				</v-tab-item>
				<v-tab-item>
					<my-page-main-my-data-license />
				</v-tab-item>
				<v-tab-item>
					<v-card>
						<v-card-title>
							<v-icon color="primary">mdi-account-group</v-icon>
							<span class="primary--text pl-2">멤버</span>
						</v-card-title>
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<strong>나의 추천인</strong>
										<!-- <v-btn
											:to="{ name: 'mypage-main-recommender-network-graph' }"
											class="ml-2"
											color="primary"
											small
										>
											자세히 보기</v-btn
										> -->
									</v-list-item-title>
									<v-list-item-subtitle>
										프로필명/자격/총 멤버수
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<strong>나의 멤버</strong>
									</v-list-item-title>
									<v-list-item-subtitle>
										총 멤버수(자격증합격멤버수)
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<strong>1st 멤버</strong>
									</v-list-item-title>
									<v-list-item-subtitle>00명 (명단보기)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</v-card>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import MyPageMainMyDataLicense from './MyPageMainMyDataLicense.vue'

export default {
	components: {
		MyPageMainMyDataLicense,
	},
	props: {},
	setup() {
		const tab = ref(null)
		const items = ref(['이벤트', '프로젝트', '자격증', '멤버'])
		const tableColumnHeaders = reactive([
			{
				text: '구분',
				value: 'type',
				sortable: false,
			},
			{
				text: '타이틀',
				value: 'title',
				sortable: false,
			},
			{
				text: '이벤트 일정',
				value: 'eventAt',
				sortable: false,
			},
			{
				text: '내용',
				value: 'content',
				sortable: false,
			},
			{
				text: '모집 인원',
				value: 'maxParticipants',
				sortable: false,
			},
			{
				text: '마감일',
				value: 'dueDate',
				sortable: false,
			},
			{
				text: '상태',
				value: 'status',
				sortable: false,
			},
		])
		const projectList = ref([])

		return {
			tab,
			items,
			tableColumnHeaders,
			projectList,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-tab {
	margin-left: 0 !important;
}

::v-deep {
	.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
		> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
		.v-slide-group__prev {
		display: none;
	}
}
</style>
