<template>
	<v-dialog
		v-model="isOpenLicenseIdUploadDialog"
		persistent
		max-width="500"
		:fullscreen="!isSmAndUp"
	>
		<v-card>
			<v-card-title>
				증명사진 올리기
				<v-spacer />
				<v-btn icon @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card-actions
				class="d-flex flex-column justify-center px-4"
				@drop.prevent="onDrop(dragover, $event, false)"
				@dragover.prevent="dragover = true"
				@dragenter.prevent="dragover = true"
				@dragleave.prevent="dragover = false"
				:style="{ 'backgroud-color: grey': dragover }"
			>
				<div v-if="file" class="file-wrapper">
					<v-row no-gutters>
						<v-col md="12" sm="12" xs="12" class="text-center mx-auto">
							<div class="d-flex flex-column">
								<div class="title">사진 자르기</div>
								<vue-cropper
									ref="previewImage"
									:aspect-ratio="3 / 4"
									:src="previewImageSrc"
									preview=".preview-image"
								/>

								<div
									class="control-wrapper"
									:class="{
										'flex-wrap': !isSmAndUp,
									}"
								>
									<v-btn color="info" @click="resetIdPhoto" class="mr-1">
										초기화
									</v-btn>
									<v-btn color="info" @click="rotateIdPhoto(90)" class="mr-1">
										90도 회전
									</v-btn>
									<v-btn color="info" @click="rotateIdPhoto(-90)" class="mr-1">
										-90도 회전
									</v-btn>
									<v-btn color="info" @click="cropIdPhoto">자르기</v-btn>
								</div>
								<div>
									<v-btn
										color="secondary"
										class="w-full mt-1"
										@click="$refs.refInputEl.click()"
									>
										컴퓨터에서 다시 선택
									</v-btn>
								</div>
								<v-divider class="mt-5" />
								<div class="d-flex flex-column mt-2">
									<div class="title">미리보기</div>
									<img
										v-if="previewImageSrc"
										:src="previewImageSrc"
										class="mx-auto cropped-image"
										alt="Cropped Image"
									/>

									<v-btn
										color="accent"
										class="mt-2"
										@click="uploadIdPhoto"
										:loading="isIdPhotoUploading"
									>
										업로드 하기
									</v-btn>
								</div>
							</div>
						</v-col>
					</v-row>
				</div>
				<div class="text-center" v-else>
					<div class="d-flex justify-center mt-4 mb-4">
						<div v-if="idPhotoUrl">
							<img :src="idPhotoUrl" height="170px" width="132px" />
						</div>
						<div v-else>
							<v-img
								max-width="120"
								src="@/assets/logo/upload-guide-image.png"
							/>
						</div>
					</div>
					<h2 class="mb-3" v-if="!idPhotoUrl">
						증명사진을 여기에
						<br :class="isSmAndDown ? 'd-block' : 'd-none'" />
						끌어다 놓으세요
					</h2>
					<v-btn color="accent" @click="$refs.refInputEl.click()">
						컴퓨터에서 선택
					</v-btn>
				</div>
				<input
					ref="refInputEl"
					@change="getPreviewContent($event.target.files)"
					type="file"
					accept="image/jpeg, image/jpg, image/png"
					:hidden="true"
				/>
			</v-card-actions>
			<v-card-text>
				<div>
					<ul class="font-weight-bold text-base">
						<li>
							가로 3cm, 세로 4cm인 학생증, 자격증, 이력서 제출용 등으로 사용하는
							천연색 상반신 정면 사진이어야 합니다.
						</li>
						<li>6개월 이내 촬영된 사진이어야 합니다.</li>
						<li>포토샵 등으로 수정한 사진은 사용할 수 없습니다.</li>
						<li>다른사람 및 사물이 노출된 사진은 사용할 수 없습니다.</li>
						<li>인물과 배경에 그림자나 빛 반사가 없어야 합니다.</li>
					</ul>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'
import UserService from '@/services/UserService'

const FILE_SIZE_BYTE_LIMIT = 20000000
const IMAGE_STANDARD_ASPECT_RATIO = 3 / 4
const ERROR_MARGIN_PERCENTAGE = 0.01
const MIN_IMAGE_STANDARD_ASPECT_RATIO =
	IMAGE_STANDARD_ASPECT_RATIO -
	IMAGE_STANDARD_ASPECT_RATIO * ERROR_MARGIN_PERCENTAGE
const MAX_IMAGE_STANDARD_ASPECT_RATIO =
	IMAGE_STANDARD_ASPECT_RATIO +
	IMAGE_STANDARD_ASPECT_RATIO * ERROR_MARGIN_PERCENTAGE

export default {
	components: {
		VueCropper,
	},
	props: {
		isOpenLicenseIdUploadDialog: {
			type: Boolean,
			required: true,
		},
		idPhotoUrl: {
			type: String,
		},
	},
	setup(props, { emit }) {
		const $vuetify = getVuetify()
		const file = ref(null)
		const dragover = ref(false)
		const refInputEl = ref(null)
		const previewImage = ref(null)
		const previewImageSrc = ref(null)
		const isOpenIssueLicense = ref(false)
		const isIdPhotoUploading = ref(false)

		const isImageAspectRatioFit = async file => {
			return new Promise(resolve => {
				const img = new Image()
				img.src = window.URL.createObjectURL(file)
				img.onload = async () => {
					const aspectRatio = img.width / img.height
					if (
						aspectRatio < MIN_IMAGE_STANDARD_ASPECT_RATIO ||
						aspectRatio > MAX_IMAGE_STANDARD_ASPECT_RATIO
					) {
						await warningSwal({
							html: '증명사진의 비율이 맞지 않습니다.',
							allowOutsideClick: true,
						})
						resolve(false)
					}
					resolve(true)
				}
			})
		}

		const isImageSizeFit = async file => {
			if (file.size > FILE_SIZE_BYTE_LIMIT) {
				warningSwal({
					html: '파일은 20MB이하로 업로드 가능합니다',
					allowOutsideClick: true,
				})
				return false
			}
			return true
		}

		const isValidImageExtension = async file => {
			const validExtensions = ['.jpg', '.jpeg', '.png']
			const isValid = new RegExp(
				'(' + validExtensions.join('|').replace(/\./g, '\\.') + ')$',
			).test(file.name)

			if (!isValid) {
				warningSwal({
					html: '.jpg, .jpeg .png 확장자 파일만<br /> 허용됩니다.',
					allowOutsideClick: true,
				})
				return false
			}
			return true
		}

		const getPreviewContent = async files => {
			if (files.length > 0) {
				const _file = files[0]
				const _isImageAspectRatioFit = await isImageAspectRatioFit(_file)
				const _isImageSizeFit = await isImageSizeFit(_file)
				const _isValidImageExtension = await isValidImageExtension(_file)

				if (
					!_isImageAspectRatioFit ||
					!_isImageSizeFit ||
					!_isValidImageExtension
				) {
					return
				}

				file.value = _file

				const src = URL.createObjectURL(file.value)

				previewImageSrc.value = src
				previewImage.value.replace(src)
			}
		}

		const onDrop = (_dragover, $event) => {
			_dragover = false
			getPreviewContent($event.dataTransfer.files)
		}

		const resetIdPhoto = () => {
			previewImage.value.reset()
		}

		const cropIdPhoto = () => {
			previewImageSrc.value = previewImage.value.getCroppedCanvas().toDataURL()
		}

		const rotateIdPhoto = deg => {
			previewImage.value.rotate(deg)
		}

		const uploadIdPhoto = async () => {
			const confirm = await confirmSwal('증명사진을 업로드 하시겠습니까?')
			if (confirm.isConfirmed) {
				isIdPhotoUploading.value = true

				const form = new FormData()

				previewImage.value.getCroppedCanvas().toBlob(async blob => {
					form.append('data', blob)

					try {
						await UserService.uploadIdPhoto(form)

						await successSwal(
							'증명사진이 업로드됐습니다.<br />발급까지는 약 2~3분 소요됩니다.',
						)

						await AuthService.init()
						file.value = null
					} catch (e) {
						warningSwal({
							html: '프로필을 업로드하는데 문제가 발생했습니다',
							allowOutsideClick: true,
						})
					} finally {
						isIdPhotoUploading.value = true
					}
				})
			}
		}

		const closeDialog = async () => {
			if (previewImage.value) {
				const confirm = await confirmSwal(
					'증명사진 보기를<br />그냥 닫으시겠습니까?',
				)
				if (confirm.isConfirmed) {
					file.value = null
					emit('update:is-open-license-id-upload-dialog', false)
				}
			} else {
				emit('update:is-open-license-id-upload-dialog', false)
			}
		}

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => props.isOpenLicenseIssueDialog,
			currentValue => {
				if (!currentValue) {
					file.value = null
					previewImage.value.src = null
				}
			},
		)

		return {
			file,
			dragover,
			isSmAndUp,
			isSmAndDown,
			refInputEl,
			previewImage,
			previewImageSrc,
			isIdPhotoUploading,
			isOpenIssueLicense,

			onDrop,
			resetIdPhoto,
			cropIdPhoto,
			rotateIdPhoto,
			uploadIdPhoto,
			getPreviewContent,
			closeDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.file-wrapper {
	width: 100%;

	.title {
		text-align: left;
	}
}

.control-wrapper {
	margin-top: 2px;
	display: flex;

	.v-btn {
		margin-top: 4px;
		width: 100%;
		flex: 1;

		@media (max-width: 600px) {
			max-width: 90px;
		}
	}
}

.preview-image {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}

.cropped-image {
	width: 100%;
	height: 100%;
	max-height: 250px;
	max-width: 180px;
}
</style>
